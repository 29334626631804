import { getUUID } from "../utils/uuid"

export default class WebSocketManager {
  constructor(url) {
    if (WebSocketManager.instance) {
      return WebSocketManager.instance;
    }
    this.url = url;
    this.socket = null;
    this.messageQueue = [];
    this.isConnected = false;
    this.reconnectAttempts = 0;
    this.maxReconnectAttempts = 5;
    this.listeners = new Map();
    this.accessKey = "01494ad7-fa57-4502-9900-1145b8629d8e";
    this.widgetId = "01494ad7-fa57-4502-9900-1145b8629d8e"; // InsuranceBot
    // this.widgetId = "83a766f5-8cb1-4586-9efc-10ea7571d60e"; // Acai demo
    this.customerId =  getUUID();
    this.send =  this.send.bind(this);
    this.addListener =  this.addListener.bind(this);
    this.first =  false;
    WebSocketManager.instance = this;
    this.connect();
  }
  static getInstance(url) {
    if (!WebSocketManager.instance) {
      WebSocketManager.instance = new WebSocketManager(url);
    }
    return WebSocketManager.instance;
  }

  connect() {
    const wsUrl = `${this.url}?access-key=${this.accessKey}&widgetId=${this.widgetId}&customerId=${this.customerId}`
    this.socket = new WebSocket(wsUrl);
    
    this.socket.onopen = this.handleOpen.bind(this);
    this.socket.onmessage = this.handleMessage.bind(this);
    this.socket.onclose = this.handleClose.bind(this);
    this.socket.onerror = this.handleError.bind(this);
  }

  handleOpen() {
    this.isConnected = true;
    this.reconnectAttempts = 0;
    this.processQueue();
    if( !this.first ){
      this.first = true;
      this.send({
        "id": getUUID(),
        "type": "text",
        "content": {
            "text": "/reset"
        }
      })
    }
    this.notifyListeners('open');
  }

  handleMessage(event) {
    const message = JSON.parse(event.data);
    this.notifyListeners('message', message);
  }

  handleClose() {
    this.isConnected = false;
    this.notifyListeners('close');
    this.attemptReconnect();
  }

  handleError(error) {
    this.notifyListeners('error', error);
  }

  attemptReconnect() {
    if (this.reconnectAttempts < this.maxReconnectAttempts) {
      this.reconnectAttempts++;
      setTimeout(() => this.connect(), 5000);
    } else {
      this.notifyListeners('maxReconnectAttempts');
    }
  }

  send(message) {
    if (this.isConnected) {
      this.socket.send(JSON.stringify(message));
    } else {
      this.messageQueue.push(message);
    }
  }

  processQueue() {
    while (this.messageQueue.length > 0) {
      const message = this.messageQueue.shift();
      this.send(message);
    }
  }

  addListener(event, callback) {
    if (!this.listeners.has(event)) {
      this.listeners.set(event, new Set());
    }
    this.listeners.get(event).add(callback);
  }

  removeListener(event, callback) {
    if (this.listeners.has(event)) {
      this.listeners.get(event).delete(callback);
    }
  }

  notifyListeners(event, data) {
    if (this.listeners.has(event)) {
      this.listeners.get(event).forEach(callback => callback(data));
    }
  }

  disconnect() {
    if (this.socket) {
      this.socket.close();
    }
  }
}